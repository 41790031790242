import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Container, Row, Col, Button } from "react-bootstrap";
import * as styles from "./Services.module.scss";

const Services = ({ data }) => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     content: wpPage(slug: { eq: "acf-common" }) {
  //       commonFields {
  // services {
  //   url
  //   serviceName
  //   description
  //   whiteIcon {
  //     mediaItemUrl
  //   }
  //   colouredIcon {
  //     mediaItemUrl
  //   }
  //   technologies {
  //     techName
  //     icon {
  //       mediaItemUrl
  //     }
  //     techLink
  //   }
  // }
  //       }
  //     }
  //   }
  // `)

  // const { services } = data?.content?.commonFields
  const services = data;
  const [focusedService, setFocusedService] = useState(services[0]);

  // console.log(focusedService);

  const serviceDetail = (
    <>
      <Row>
        <Col md={12}>
          <h3>{focusedService?.serviceName}</h3>
          <p className="pb-2">{focusedService?.description}</p>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Row>
            {focusedService?.technologies?.map((item, ind) => (
              <Col
                key={`${focusedService?.serviceName}_${ind}`}
                xs={3}
                md={2}
                className={"pb-2"}
              >
                <img
                  src={item?.icon?.mediaItemUrl}
                  height="30"
                  width="30"
                  loading="lazy"
                  alt={item?.techName}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      {/* <Link to={focusedService?.url}> */}
      <Button className="btn_black_border mt-4 mb-5 mb-md-0" disabled>
        Learn More
      </Button>
      {/* </Link> */}
    </>
  );

  return (
    <section>
      <Container>
        <Row className={"justify-content-center"}>
          <Col xs={12} md={8} lg={6}>
            <div className="text-center mb-3">
              <h2 className={`pb-2 main-heading-36`}>
                Software{" "}
                <span className="text-primary">
                  {" "}
                  Consulting & <br />
                  Development Services...{" "}
                </span>
              </h2>
              <p className={"pb-5"}>
                ...with the perfect blend of technology, expertise and
                innovation.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Row className={"justify-content-center"}>
              {services?.map((item, ind) => (
                <React.Fragment key={`service_${ind}`}>
                  <Col
                    md={6}
                    lg={6}
                    xl={4}
                    className="d-flex justify-content-center"
                  >
                    <div
                      className={`${styles.serviceCard} ${
                        focusedService?.serviceName === item?.serviceName &&
                        styles.focused
                      }`}
                      id={`service_${ind}`}
                      onClick={() => setFocusedService(item)}
                    >
                      <div>
                        <img
                          className="black"
                          src={
                            focusedService?.serviceName === item?.serviceName
                              ? item?.whiteIcon?.mediaItemUrl
                              : item?.colouredIcon?.mediaItemUrl
                          }
                          alt="Services"
                          loading="lazy"
                        />
                        <p>{item?.serviceName}</p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} className={"d-block d-md-none pl-4 pt-3 "}>
                    {focusedService?.serviceName === item?.serviceName &&
                      serviceDetail}
                  </Col>
                </React.Fragment>
              ))}
            </Row>
          </Col>

          <Col md={6} className={`pl-2 pt-0 pl-md-5 ${styles.serviceDetail}`}>
            {serviceDetail}
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Services;
