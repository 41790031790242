import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col, Modal } from "react-bootstrap";

// const DownloadForm = React.lazy(() => import("./DownloadForm"))
import DownloadForm from "./DownloadForm";

import * as styles from "./DownloadGuides.module.scss";

const DownloadModal = ({ guideType, img, children }) => {
  // const isSSR = typeof window === "undefined"

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button
        // variant={guideType === "outsourcing" ? "primary" : "outline-primary"}
        onClick={handleShow}
        className={"btn_black_border"}
      >
        Download PDF
      </button>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header className={styles.modalHeader} closeButton />
        <Modal.Body className="p-0">
          <Row className="align-items-center m-0">
            <Col className="d-none d-md-block">
              <div className="p-4">{img}</div>
            </Col>
            <Col className="py-4" style={{ backgroundColor: "#f2f2f2" }}>
              {children}
              <div className="pt-4 px-3">
                <DownloadForm guideType={guideType} />
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

const DownloadGuides = (props) => (
  <section className={"pt-0"}>
    <Container>
      <Row className="justify-content-center">
        {props?.p && (
          <Col xs={12} md={6}>
            <p className="text-center">
              Meanwhile, let's take a look at our product portfolio, or better
              yet you can download our useful resources and guides to help you
              get started.
            </p>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={6}>
          <h2 className={`main-heading-h2 main-heading-36`}>
            Download <span className="text-primary">PDF's</span>
          </h2>
          <div className="mb-4">&nbsp;</div>
          <h3 className={"h4 font-weight-bold"}>A Guide to Outsourcing</h3>
          <div className="description-download">
            A practical guide to facilitate businesses in software development
            outsourcing.
          </div>
          <div className="mt-3">
            <DownloadModal
              guideType="outsourcing"
              img={
                <StaticImage
                  src="../../images/illustrations/outsourcing-guide.png"
                  alt="Download outsourcing guide"
                  placeholder="blurred"
                />
              }
            >
              <div className="h2 mb-4">
                <span className="text-primary">Free Guide</span> to <br />
                Outsourcing Software Development
              </div>
              <div className="h5">What's in this e-book?</div>
              <ul className={styles.list}>
                <li>A comprehensive view of outsourcing business models</li>
                <li>Tips for choosing the perfect outsourcing agency</li>
                <li>
                  Insights on how to collaborate with outsourcing firms
                  effectively
                </li>
              </ul>
            </DownloadModal>
          </div>
          <div className="mb-4">&nbsp;</div>
          <h3 className={"h4 font-weight-bold"}>How to Hire a Remote Team ?</h3>
          <div className="description-download">
            Learn how to hire, manage and work with remote development teams.
          </div>
          <div className="mt-3">
            <DownloadModal
              guideType="remote-team"
              img={
                <StaticImage
                  src="../../images/illustrations/remote-guide.png"
                  alt="Download how to hire a remote team guide"
                  placeholder="blurred"
                />
              }
            >
              <div className="h2 mb-4">
                Want to hire and <br />
                <span className="text-primary">
                  manage remote teams efficiently?
                </span>
              </div>
              <div style={{ color: "#212529" }}>
                We’ve created a complete checklist for hiring and collaborating
                with remote teams.
              </div>
            </DownloadModal>
          </div>
        </Col>
        <Col md={6} className="mt-5 mt-md-0">
          <StaticImage
            src="../../images/pdf-illustraion.svg"
            alt="Download PDF"
            placeholder="blurred"
          />
        </Col>
      </Row>
    </Container>
  </section>
);

export default DownloadGuides;
