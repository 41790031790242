import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Row, Col, Container } from "react-bootstrap";
import * as styles from "./Banner.module.scss";

const TypingEffect = React.lazy(() => import("./TypingEffect"));
const BannerSlider = React.lazy(() => import("./BannerSlider"));

const Banner = () => {
  const isSSR = typeof window === "undefined";

  return (
    <section className={styles.section}>
      <Container>
        <Row className="align-items-center">
          <Col lg={6}>
            <h1>
              Software Development Consulting for <br />
              {!isSSR && (
                <React.Suspense fallback={<div>Innovators</div>}>
                  <TypingEffect />
                </React.Suspense>
              )}
            </h1>
            <p className={"paragraph"}>
              Tv2u offers software development consulting services for
              businesses, startups, and enterprises. Partner with a reliable
              software development company to scale up your engineering
              capacity.
            </p>
            <div className="mt-5">
              <Link to="/contact-us/">
                <button
                  className={`btn_white_border`}
                  id="hp_hero_section_start_project_btn_gt"
                >
                  Schedule a Call
                </button>
              </Link>
            </div>
          </Col>
          <Col lg={6} className="mt-5 mt-lg-0">
            {!isSSR && (
              <React.Suspense
                fallback={
                  <StaticImage
                    src="../../images/banner/innovators.png"
                    alt="Innovators"
                    placeholder="blurred"
                    loading="lazy"
                    width={610}
                    height={376}
                  />
                }
              >
                <BannerSlider />
              </React.Suspense>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
