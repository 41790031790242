import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";

import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";
import { FaExclamationCircle } from "@react-icons/all-files/fa/FaExclamationCircle";

import { isEmail, encodeToFormData } from "../../utils";

const DownloadForm = ({ guideType }) => {
  const [status, setStatus] = useState("init");

  const initValues = { email: "", subscribe: false };
  const [values, setValues] = useState(initValues);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { type, name } = e.target;
    if (type === "checkbox") values[name] = e.target.checked;
    else values[name] = e.target.value;
    setValues({ ...values });

    if (errors.hasOwnProperty(name)) {
      delete errors[name];
      setErrors(errors);
    }
  };

  const validate = (data) => {
    const newErrors = {};
    for (const key in data) {
      const value = data[key];
      switch (key) {
        case "email":
          if (value.trim() === "") newErrors[key] = "This field is required!";
          else if (!isEmail(value)) newErrors[key] = "Email must be valid!";
          break;
        default:
          break;
      }
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setStatus("submitting");
    // const cfFormId = guideType === "outsourcing" ? `8680` : `8689`;
    const cfFormId = guideType === "outsourcing" ? `7000` : `7001`;

    const url = `https://cms.tv2uasia.com/api/contactusforms/?id=${cfFormId}`;
    // values["formID"] = cfFormId;
    const formData = encodeToFormData(values);
    try {
      const result = await fetch(url, {
        method: "POST",
        body: formData,
      }).then((res) => res.json());
      if (
        !result.hasOwnProperty("error") ||
        result.status === "mail_sent" ||
        result.status === "mail_failed"
      ) {
        setValues(initValues);
        setStatus("success");
      } else {
        // console.log(result)
        throw new Error("Server error!");
      }
    } catch (err) {
      setStatus("failed");
    }
  };

  return (
    <Form name="Free Guide Form" method="POST" onSubmit={handleSubmit}>
      <Form.Group controlId="email">
        <Form.Control
          type="email"
          placeholder="Email"
          name="email"
          value={values.email}
          onChange={handleChange}
          isInvalid={errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="pl-2 text-center" controlId="subscribe">
        <Form.Check
          type="checkbox"
          label="I also want to subscribe the Newsletters"
          name="subscribe"
          value={values.subscribe}
          checked={!!values.subscribe}
          onChange={handleChange}
        />
      </Form.Group>
      <div className="px-2">
        <button
          // variant="primary"
          className={"btn_black_border"}
          type="submit"
          aria-label="Send"
          disabled={status === "submitting"}
          style={{ minWidth: "200px" }}
        >
          {status === "submitting" ? (
            <>
              <Spinner
                variant="light"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="sr-only">Sending</span>
            </>
          ) : (
            "Send me the e-book"
          )}
        </button>
      </div>
      {status === "success" && (
        <div className="mt-3 px-2 small text-success">
          <FaCheckCircle /> Success! the guide has been sent to your email.
        </div>
      )}
      {status === "failed" && (
        <div className="mt-3 px-2 small text-danger">
          <FaExclamationCircle /> Oops! Something went wrong. Please try again.
        </div>
      )}
    </Form>
  );
};

export default DownloadForm;
