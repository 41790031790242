import React, { useState } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import Slider from "react-slick";
import { BsArrowLeft } from "@react-icons/all-files/bs/BsArrowLeft";
import { BsArrowRight } from "@react-icons/all-files/bs/BsArrowRight";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./Industries.module.scss";

const Services = ({ data }) => {
  const [current, setCurrent] = useState(0);

  // const data = useStaticQuery(graphql`
  //   query {
  //     content: wpPage(slug: { eq: "acf-common" }) {
  //       commonFields {
  // industries {
  //   name
  //   slug
  //   tesdescription
  //   tesimage {
  //     localFile {
  //       childImageSharp {
  //         gatsbyImageData(
  //           width: 610
  //           quality: 100
  //           placeholder: BLURRED
  //           formats: [AUTO, WEBP, AVIF]
  //         )
  //       }
  //     }
  //   }
  // }
  //       }
  //     }
  //   }
  // `)

  // const { industries } = data?.content?.commonFields
  const industries = data;

  const settings = {
    dots: true,
    arrows: true,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    speed: 900,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    dotsClass: "home_button__bar",
    className: "slick-center",
    lazyLoad: "ondemand",
    beforeChange: (current, next) => setCurrent(next),
    customPaging: (i) => <div>{industries[i]?.name}</div>,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          centerMode: false,
          centerPadding: "0px",
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          centerPadding: "0px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 578,
        settings: {
          centerMode: false,
          centerPadding: "0px",
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function Arrow(props) {
    const { first, onClick } = props;
    return (
      <div className={styles.iconContainer}>
        <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
          <BsArrowRight className={styles.portfolioArrowIcon} />
        </div>
      </div>
    );
  }
  function PrevArrow(props) {
    const { first, onClick } = props;
    return (
      <div className={styles.iconContainerLeft}>
        <div className={styles.portfolioArrowIconCover} onClick={onClick}>
          <BsArrowLeft
            className={styles.portfolioArrowIcon}
            onClick={onClick}
          />
        </div>
      </div>
    );
  }

  return (
    <section className={styles.section}>
      <div className="text-center">
        <h2 className={`pb-25 main-heading-36`}>
          <span className="text-primary"> Industries</span> in Focus
        </h2>
      </div>

      <Container>
        <Row className={"justify-content-center"}>
          <Col xs={12} md={12} lg={12} xl={12}>
            {
              <Slider {...settings} className={styles.sliderContainer}>
                {industries?.map((item, ind) => {
                  // const image = getImage(item.tesimage.localFile);

                  return (
                    <div key={ind} className="d-flex justify-content-center">
                      <div className={styles.industryCard}>
                        <Image
                          src={item.tesimage.mediaItemUrl}
                          alt={item.name}
                          loading="lazy"
                          height="100%"
                          width="100%"
                        />
                        {/* <GatsbyImage
                          image={image}
                          alt={item.name}
                          loading="lazy"
                          height="100%"
                          width="100%"
                        {/* /> */}

                        <h3 className={"text-center text-light pt-3"}>
                          {item?.name}
                        </h3>
                        <Button
                          className="btn_white_border"
                          onClick={() => navigate(`/${item?.slug}`)}
                          disabled
                        >
                          Learn More
                        </Button>
                        {/* <div /> */}
                      </div>
                    </div>
                  );
                })}
              </Slider>
            }
            <Row className="justify-content-center">
              <Col xs={12} md={10} lg={8}>
                <p className="pt-5 text-center pt-5">
                  {industries && industries[current]?.tesdescription}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Services;
