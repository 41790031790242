import React from "react";

import * as styles from "./TechSlider.module.scss";
import s1 from "../../images/ppc/s1.svg";
import s2 from "../../images/ppc/s2.svg";
import s3 from "../../images/ppc/s3.svg";
import s4 from "../../images/ppc/s4.svg";
import s5 from "../../images/ppc/s5.svg";
import s6 from "../../images/ppc/s6.svg";

import s7 from "../../images/new-image/1.svg";

import s8 from "../../images/new-image/2.svg";

import s9 from "../../images/new-image/3.svg";
import s10 from "../../images/ppc/s7.png";

import Slider from "react-slick";
const TechSlider = () => {
  const settings = {
    slidesToShow: 7,
    slidesToScroll: 2,
    initialSlide: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 100,
    infinite: true,
    arrows: false,
    dots: false,
    pauseOnHover: false,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          autoplay: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const data = [
    {
      image: s1,
    },
    {
      image: s2,
    },
    {
      image: s7,
    },
    {
      image: s4,
    },
    {
      image: s5,
    },
    {
      image: s6,
    },

    {
      image: s8,
    },
    {
      image: s9,
    },
    {
      image: s10,
    },
  ];
  return (
    <div className={`${styles.sliderMain}`}>
      <h2>
        Our <span className="text-primary">Products</span>{" "}
      </h2>
      <Slider className={`mt-4`} {...settings}>
        {data?.map((e, i) => (
          <div key={i}>
            <img src={e?.image} alt="logo" style={{ maxHeight: "160px" }} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TechSlider;
