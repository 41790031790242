import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import * as styles from "./Engagement.module.scss";
import illustration from "../../images/home/engagement/Illustration.svg";
import dedicated_development_team from "../../images/home/engagement/dedicated_development_team.svg";
import product_dev from "../../images/home/engagement/product_dev.svg";
import resource_aug from "../../images/home/engagement/resource_aug.svg";

const Services = () => {
  const models = [
    {
      icon: product_dev,
      heading: "Product Development",
      desc: "The project-based model is designed to help you get your product built from ideation to deployment by a team of expert software engineering consultants.",
    },
    {
      icon: resource_aug,
      heading: "Staff/Resource Augmentation",
      desc: "Our staff augmentation model is intended to help companies find the right skills for their project.",
    },

    {
      icon: dedicated_development_team,
      heading: "Dedicated Development Team",
      desc: "Our team of developers is highly qualified and dedicated to delivering quality development services to clients.",
    },
  ];

  return (
    <section>
      <Container>
        <Row>
          <Col md={6}>
            <div>
              <h2 className="main-heading-36">
                Engagement <span className="text-primary">Models</span>
              </h2>
              <p className="pb-5 pt-3 mb-0">
                We solve your biggest business pains and help you succeed.
                <br /> Here is how we can work together
              </p>
              <div className={styles.illustrationContainer}>
                <img
                  src={illustration}
                  alt="engagement_model"
                  loading="lazy"
                  height="100%"
                  width="100%"
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            {models?.map((item, ind) => (
              <div
                className={`${styles.engagementCards} ${
                  ind === 1 && "ml-md-5"
                } p-3`}
                key={item.heading}
              >
                <img
                  src={item?.icon}
                  alt="staff_resource"
                  loading="lazy"
                  height="55px"
                />
                <div className="pl-3">
                  <h3 className="simple-font-weight text-20">
                    {item?.heading}
                  </h3>
                  <p className="mb-0">{item?.desc}</p>
                </div>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Services;
