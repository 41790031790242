import React, { useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import playIcon from "../../images/home/Play_Button.svg";
import * as styles from "./VideoSection.module.scss";
import { StaticImage } from "gatsby-plugin-image";

const Services = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  return (
    <section className={styles.section}>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        centered
        size="lg"
        contentClassName={styles.modalContent}
      >
        <Modal.Body className={styles.modalBody}>
          <iframe
            src="https://www.youtube.com/embed/W1UeM7NFC8A?autoplay=1"
            allow="autoplay"
            title="Tv2u"
          ></iframe>
        </Modal.Body>
      </Modal>

      <Container className="d-flex justify-content-center align-items-center">
        <Row>
          <Col
            md={6}
            className="d-flex justify-content-center align-items-center pr-md-5"
          >
            <div className="pr-md-5">
              <h2 className="main-heading-36">
                Hire the Right{" "}
                <span className="text-primary">
                  Software Consultants And Developers{" "}
                </span>
              </h2>
              <div>
                <p className="mb-0">
                  Our development team consists of expert 500+ software
                  consulting engineers, project managers, and designers. We
                  build scalable products for businesses globally.
                </p>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className={styles.videoBanner}>
              <div className={styles.imgContainer}>
                <StaticImage
                  src={"../../images/vImg.webp"}
                  placeholder="blurred"
                  loading="lazy"
                  quality="100"
                  alt="play_invozone_video"
                  imgStyle={{ borderRadius: 7 }}
                />
                <img
                  loading="lazy"
                  alt="play"
                  src={playIcon}
                  height="70px"
                  onClick={handleShow}
                  id="hp_video_section_gt"
                  className={styles.play_icon}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Services;
